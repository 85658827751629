export function copyVar(val) {
  const to_copy = "{{ $" + val + " }}";
  const input = document.createElement("input");
  input.setAttribute("type", "text");
  input.setAttribute("value", to_copy);
  input.setAttribute("id", "temporary_input");
  const clicked = document.getElementById(val);
  clicked.prepend(input);
  input.focus();
  input.select();
  document.execCommand("copy");
  document.getElementById("temporary_input").remove();
  const msg = document.createElement("span");
  msg.setAttribute("id", "show_copied");
  msg.innerHTML = "Copied";
  clicked.append(msg);

  setTimeout(() => {
    msg.remove();
  }, 2000);
}

export const editor_data = {
  my_data: {},
  editor_options: {
    height: "400px",
    file_picker_types: "image",
    /* and here's our custom image picker*/
    // eslint-disable-next-line no-unused-vars
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      var _this = this;
      input.onchange = function () {
        var file = this.files[0];
        var reader = new FileReader();
        reader.onload = () => {
          var id = "blobid" + new Date().getTime();
          var blobCache = _this.editorUpload.blobCache;
          var base64 = reader.result.split(",")[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    },
  },
  variables: [],
  selected_counter_parties: [],
  name: "",
  error: "",
};